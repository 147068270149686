import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import env from '../env'

// https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#4-create-a-useapi-hook-for-accessing-protected-apis-with-an-access-token
export const useApi = (url: string, method: string, fetchOptions: any) => {
  const { getAccessTokenSilently } = useAuth0()
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: null,
  })
  const [refreshIndex, setRefreshIndex] = useState(0)

  useEffect(() => {
    ;(async () => {
      try {
        const accessToken = await getAccessTokenSilently({ audience: env.AUTH0_AUDIENCE, scope: env.AUTH0_SCOPE })
        const res = await fetch(`${env.SERVER_URL}${url}`, {
          method,
          headers: {
            // ...fetchOptions.headers,
            // Add the Authorization header to the existing headers
            Authorization: `Bearer ${accessToken}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          ...fetchOptions,
        })

        if (res.status !== 200) {
          const parsedError = await res.json()
          throw new Error(parsedError.message)
        }

        setState({
          ...state,
          data: await res.json(),
          error: null,
          loading: false,
        })
      } catch (error) {
        setState({
          ...state,
          // @ts-ignore
          error: error.message,
          loading: false,
        })
      }
    })()
    // @NOTE: do not list all the dependencies here cause it will cause infinite api calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    ...state,
    refresh: () => setRefreshIndex(refreshIndex + 1),
  }
}
