import React from 'react'
import './App.css'
import { Home } from './pages/Home'
import { NavigationBar } from './components/NavigationBar'
import { Route, Routes } from 'react-router-dom'
import { Profile } from './pages/Profile'
import { Footer } from './components/Footer'
import useLoadUserState from './userState/useLoadUserState'
import Loading from './components/Loading'
import { CheckoutProcessing } from './pages/CheckoutProcessing'
import { Success } from './pages/Success'
import Logout from './components/Logout'
import { Assistant } from './pages/Assistant'

function App() {
  const { isLoading, isAuthenticated } = useLoadUserState()

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="App min-h-screen">
      <NavigationBar isAuthenticated={isAuthenticated} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/processing" element={<CheckoutProcessing />} />
        <Route path="/success" element={<Success />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/assistant" element={<Assistant />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App
