import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const AuthNav = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

  return isAuthenticated ? (
    <button onClick={() => logout({ returnTo: window.location.origin })} id="logoutBtn" className="logout-btn lg:ml-4">
      terminar sesión
    </button>
  ) : (
    <button
      onClick={() => loginWithRedirect({ ui_locales: 'es', screen_hint: 'login' })}
      id="loginBtn"
      className="login-btn lg:ml-4"
    >
      iniciar sesión
    </button>
  )
}

export default AuthNav
