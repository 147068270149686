import React, { ReactNode, useState } from 'react'

interface QuestionProps {
  question: string
  children: ReactNode
  defaultHide?: boolean
}
export const Question = ({ question, children, defaultHide = true }: QuestionProps) => {
  const [isHidden, setIsHidden] = useState(defaultHide)
  const toggleHidden = () => {
    setIsHidden(!isHidden)
  }

  return (
    <>
      <div
        className={`mb-4 text-base font-bold block faq-question cursor-pointer ${
          isHidden ? '' : 'question-show-answer'
        }`}
        onClick={() => toggleHidden()}
      >
        {question}
      </div>
      <div className={`mb-7 px-8 py-2 block ${isHidden ? 'answer-hidden' : 'answer-display'}`}>{children}</div>
    </>
  )
}
