import React from 'react'

interface PkgStepOneProps {
  pkgName: string
  pkgClassName: string
  wizardPickPackage: any
  price: string
  headerImg: any
  bottleImg: any
  bottleImgWidth?: string
  imgAltText: string
  description: string
}

const PkgStepOne = ({
  pkgName,
  pkgClassName,
  wizardPickPackage,
  price,
  headerImg,
  bottleImg,
  bottleImgWidth = '90px',
  imgAltText,
  description,
}: PkgStepOneProps) => (
  <div className={`basis-1/3 wizard-item-wrapper ${pkgClassName}`} onClick={() => wizardPickPackage(pkgName, '')}>
    <div className="p-6 mt-4">
      <img src={headerImg} alt={imgAltText} width="200px" className="mx-auto" />
      <span className="text-2xl font-bold text-center block my-6">{description}</span>
      <img src={bottleImg} alt={imgAltText} width={bottleImgWidth} className="mx-auto" />
    </div>
    <div className="sticky top-[100vh] details p-7 text-center">
      <ul>
        <li className="text-3xl font-bold">{price}</li>
        <li className="text-base">Envio gratis!</li>
        <li className="text-base">20% off en tu primer envío</li>
      </ul>
    </div>
  </div>
)

export default PkgStepOne
