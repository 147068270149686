import { useApi } from '../hooks/use-api'

interface ProfileData {
  email: string
  name: string
}

export function Profile() {
  // @TODO: change this to be configurable
  const { loading, error, data: user } = useApi('/v1/user-accounts', 'GET', {})

  if (loading) {
    return <div>Loading...</div>
  }
  if (error || user === null) {
    console.log('error ==> ', error)
    return (
      <>
        <div>Oops {error}</div>
      </>
    )
  }

  return (
    <>
      {/* Main Body Pane */}

      <div className="flex flex-wrap text-left">Profile</div>
      <span>Name: {(user as ProfileData).name}</span>
      <span>Email: {(user as ProfileData).email}</span>
    </>
  )
}
