import React from 'react'
import './Loading.css'

export const Loading = () => (
  <div className="mt-0 h-14 w-full">
    <span className="loader m-auto block"></span>
  </div>
)

export default Loading
