import fblogo from '../resources/fb-icon.png'
import instagramlogo from '../resources/instagram-icon.png'

export function Footer() {
  return (
    <>
      <div className="sticky top-[100vh] footer-color px-8 pt-10 pb-6 footer-container">
        <div className="px-2 text-left">
          <div className="flex flex-col md:flex-row mx-2">
            <div className="lg:w-1/3 px-2 h-44">
              <h4 className="text-base leading-7 mb-5">Salvaje Vinos</h4>
              <ul className="text-sm">
                <li className="mb-3">
                  <a href="https://www.salvajevinos.com/pages/nuestra-historia" target="_blank" rel="noreferrer">
                    Quiénes somos
                  </a>
                </li>
                <li className="mb-3">
                  <a href="https://www.salvajevinos.com/blogs/blog-salvaje" target="_blank" rel="noreferrer">
                    Blog
                  </a>
                </li>
              </ul>
            </div>
            <div className="lg:w-1/3 px-2 h-44">
              <h4 className="text-base leading-7 mb-5">Contáctanos</h4>
              <ul className="text-sm">
                <li className="mb-3">hello@salvajevinos.com</li>
                <li>
                  <a
                    href="https://www.facebook.com/Salvaje-Vinos-104982354520285/"
                    target="_blank"
                    rel="noreferrer"
                    className="fb-icon inline-block mr-4"
                  >
                    <img src={fblogo} alt="Facebook" width="30px" />
                  </a>
                  <a
                    href="https://www.instagram.com/salvajevinos/"
                    target="_blank"
                    rel="noreferrer"
                    className="instagram-icon inline-block"
                  >
                    <img src={instagramlogo} alt="Instagram" width="30px" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="lg:w-1/3 px-2 h-44">
              {/**
               * <h4 className="text-base leading-7 mb-5">Nuestro Newsletter</h4>
               * <input type="text" placeholder="correo electrónico" className="px-7 h-8 w-56 text-sm" />
               * <button className="text-sm button-color px-7 block mt-3 h-8 w-56">Suscribir</button>
               */}
            </div>
          </div>
        </div>
        <div className="pt-6 pb-0 text-sm text-center">
          <ul className="list-none">
            <li className="inline mx-2">© 2021, Salvaje Vinos.</li>
            <li className="inline mx-2">
              <a
                href="https://www.salvajevinos.com/pages/terminos-y-condiciones-de-uso"
                target="_blank"
                rel="noreferrer"
              >
                términos & condiciones
              </a>
            </li>
            <li className="inline mx-2">
              <a href="https://www.salvajevinos.com/pages/aviso-de-privacidad" target="_blank" rel="noreferrer">
                privacidad
              </a>
            </li>
            <li className="inline mx-2">
              <a href="https://www.salvajevinos.com/pages/politicas-de-compra" target="_blank" rel="noreferrer">
                politicas de compras
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}
