import logo from '../salvajevinos.png'
import { Link } from 'react-router-dom'
import Authentication from './Authentication'
import { useState } from 'react'

export const NavigationBar = ({ isAuthenticated }: { isAuthenticated: boolean }) => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)

  const toggleClass = () => {
    setMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <>
      <nav className="flex items-center justify-between flex-wrap nav-bar-color pl-8 pr-8 pt-4 pb-4">
        <div className="flex items-center flex-no-shrink text-white mr-6">
          <img src={logo} className="App-logo" alt="Salvaje Vinos logo" />
        </div>
        <div className="block lg:hidden">
          <button
            className="flex items-center px-3 py-2 border rounded text-white border-white-400 hover:text-purple hover:border-white"
            onClick={toggleClass}
          >
            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
        <div
          className={
            isMobileMenuOpen
              ? 'w-full flex-grow lg:flex lg:items-center lg:w-auto pt-6 lg:pt-0'
              : 'w-full flex-grow lg:flex lg:items-center lg:w-auto hidden pt-6 lg:pt-0'
          }
        >
          <div className="text-sm text-center lg:flex-grow lg:text-right">
            <Link to="/" className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-purple">
              home
            </Link>

            {/*isAuthenticated && (
              <Link to="/profile" className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-purple lg:ml-4">
                perfil
              </Link>
            )*/}

            <Authentication />
          </div>
        </div>
      </nav>
    </>
  )
}
