const getMonth = (month: number) => {
  let result = ''
  switch (month) {
    case 1:
      result = 'Ene'
      break
    case 2:
      result = 'Feb'
      break
    case 3:
      result = 'Mar'
      break
    case 4:
      result = 'Abr'
      break
    case 5:
      result = 'Mayo'
      break
    case 6:
      result = 'Jun'
      break
    case 7:
      result = 'Jul'
      break
    case 8:
      result = 'Ago'
      break
    case 9:
      result = 'Sept'
      break
    case 10:
      result = 'Oct'
      break
    case 11:
      result = 'Nov'
      break
    case 12:
      result = 'Dic'
      break
  }
  return result
}

export const getFirstPaymentDate = () => {
  const currentDate = new Date()
  const day = currentDate.getDate()
  const year = currentDate.getFullYear()
  const currentMonth = currentDate.getMonth() + 1

  return `${day}/${getMonth(currentMonth)}/${year}`
}

export const getNextPaymentDate = () => {
  const currentDate = new Date()
  const day = currentDate.getDate()
  const nextMonth = currentDate.getMonth() + 2
  const year = currentDate.getFullYear()

  return `${day}/${getMonth(nextMonth)}/${year}`
}

export const getShippingDate = () => {
  const currentDate = new Date()
  const dayOfTheMonth = currentDate.getDate()
  const currentMonth = currentDate.getMonth()

  if (dayOfTheMonth > 10) {
    const date = new Date()
    date.setMonth(currentMonth + 1)
    date.setDate(1)
    const displayDate = date.toLocaleDateString()
    return displayDate
  }

  const date = new Date()
  date.setMonth(currentMonth)
  date.setDate(10)
  const displayDate = date.toLocaleDateString()
  return displayDate
}
