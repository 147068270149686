import Loading from '../components/Loading'
import env from '../env'
import React, { useEffect, useCallback, useReducer, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'

const sampleQuestions = [
  'Que vino tinto me recomiendas para una cena con amigos?',
  'Que sugerencia tienes si me gustan bk wines ?',
]

function promptDataReducer(
  state: any[],
  action: {
    index?: number
    answer?: string | undefined
    status?: string
    query?: string | undefined
    type?: 'remove-last-item' | string
  }
) {
  // set a standard state to use later
  let current = [...state]

  if (action.type) {
    switch (action.type) {
      case 'remove-last-item':
        current.pop()
        return [...current]
      default:
        break
    }
  }

  // check that an index is present
  if (action.index === undefined) return [...state]

  if (!current[action.index]) {
    current[action.index] = { query: '', answer: '', status: '' }
  }

  current[action.index].answer = action.answer

  if (action.query) {
    current[action.index].query = action.query
  }
  if (action.status) {
    current[action.index].status = action.status
  }

  return [...current]
}

export function Assistant() {
  const [question, setQuestion] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const [answers, setAnswers] = useState<string[]>([])
  const [convoId, setConvoId] = useState<string | undefined>(undefined)
  const [isResponding, setIsResponding] = useState(false)
  const [promptIndex, setPromptIndex] = useState(0)
  const [promptData, dispatchPromptData] = useReducer(promptDataReducer, [])

  const handleSubmit = useCallback(async () => {
    if (!question || question === '') {
      return
    }

    dispatchPromptData({ index: promptIndex, answer: undefined, query: question })
    setIsResponding(true)
    setIsLoading(true)

    const response = await fetch(`${env.SERVER_URL}/v2/assistant`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: '*',

        // Authorization: `Bearer ${accessToken}`,
      },
      method: 'POST',
      body: JSON.stringify({
        userMessage: question,
        conversationId: convoId,
      }),
    })

    const jsonRes = (await response.json()) as { data: { conversationId: string; message: string; options?: string[] } }
    setPromptIndex((x) => {
      return x + 1
    })

    const mergedAnswers = []

    mergedAnswers.push(jsonRes.data.message)

    if (jsonRes.data.options) {
      jsonRes.data.options.forEach((opt) => {
        mergedAnswers.push(opt)
      })
    }
    //setAnswers(mergedAnswers)
    setConvoId(jsonRes.data.conversationId)
    dispatchPromptData({
      index: promptIndex,
      answer: mergedAnswers.toString(),
      query: question,
    })

    handleResetPrompt()
  }, [convoId, promptIndex, question])

  function handleResetPrompt() {
    setIsResponding(false)
    setIsLoading(false)
    setQuestion('')
  }

  useEffect(() => {
    if (question) {
      handleSubmit()
    }
  }, [])

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen" onClick={(e) => e.stopPropagation()}>
      <div className="m-10 grid grid-cols-5 gap-1 bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 overflow-y-auto">
        <div className="col-span-5">
          <h2>Salvaje Vinos AI</h2>
        </div>

        {promptData.length === 0 && (
          <div className="col-span-5 h-24">
            <h3>Ejemplos de preguntas</h3>
            <ul>
              {sampleQuestions.map((question) => {
                const key = question.replace(/\s+/g, '_')
                return <li key={key}>{question}</li>
              })}
            </ul>
          </div>
        )}

        {promptData.map((prompt, i) => {
          if (!prompt.query) return <></>

          return (
            <>
              {prompt.query && (
                <div className="col-span-5 mx-4 [overflow-anchor:none] mb-6">
                  <div className="prose text-scale-1000">{prompt.query}</div>
                </div>
              )}

              <div className="col-span-2" />
              <div className="col-span-3 px-4 [overflow-anchor:none] mb-6">
                <div>
                  <ReactMarkdown>{prompt.answer}</ReactMarkdown>
                </div>
              </div>
            </>
          )
        })}

        {isLoading && (
          <div className="col-span-5 mt-4">
            <div className="flex items-center justify-center">
              <Loading />
            </div>
          </div>
        )}

        <div className="col-span-4 mt-4">
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="text"
            value={question}
            placeholder={
              isLoading || isResponding
                ? 'Esperando respuesta ...'
                : 'Pregunta cualquier cosa sobre el catalogo de Salvaje Vinos'
            }
            onChange={(e) => setQuestion(e.target.value)}
            onKeyPress={handleInputKeyPress}
          />
        </div>

        <div className="col-span-1 mt-4">
          <div className="flex items-center justify-center">
            <button
              type="button"
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              disabled={isLoading || isResponding}
            >
              Preguntar
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
