import { useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import env from '../env'
import { currentUserState } from './state'

export interface UserState {
  name: string
  userId: string
  email: string
  picture: string
  stripeId: string
}

const useLoadUserState = () => {
  const [, setCurrentUser] = useRecoilState(currentUserState)
  const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()

  const loadUserState = useCallback(async () => {
    if (!user) {
      setCurrentUser(undefined)
      return
    }

    if (!user.sub) {
      throw new Error('No userSub provided')
    }

    if (!user.email) {
      throw new Error('No user email provided')
    }

    const accessToken = await getAccessTokenSilently({ audience: env.AUTH0_AUDIENCE, scope: env.AUTH0_SCOPE })
    // create user in db if needed
    const URL = env.SERVER_URL
    const createUserResponse = await fetch(`${URL}/v1/user-accounts`, {
      method: 'GET',
      headers: {
        // Add the Authorization header to the existing headers
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    })

    if (createUserResponse.status !== 200) {
      const error = await createUserResponse.json()
      console.log('error ==> ', error)
    }
    const userRes = await createUserResponse.json()

    if (!userRes.id) {
      throw new Error('No user available')
    }

    const userState: UserState = {
      email: user.email,
      name: userRes.name,
      userId: user.sub,
      picture: user.picture ?? '',
      stripeId: userRes.stripeId,
    }

    setCurrentUser(userState)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    loadUserState()
  }, [loadUserState])

  return { isAuthenticated, isLoading }
}

export default useLoadUserState
