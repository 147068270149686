import { useLocation } from 'react-router-dom'
import { useApi } from '../hooks/use-api'
import Loading from '../components/Loading'

export function CheckoutProcessing() {
  const query = new URLSearchParams(useLocation().search)
  const pkgNameQuery = query.get('pkg')
  const varietyQuery = query.get('variety')

  const {
    loading,
    error,
    data: user,
  } = useApi('/v1/create-checkout-session', 'POST', {
    body: JSON.stringify({
      pkgName: pkgNameQuery,
      variety: varietyQuery,
    }),
  })

  if (error) {
    console.log('error ==> ', error)
    return (
      <>
        <div className="w-full processing_screen p-4">
          <div className="justify-center text-center w-full text-black mt-6">
            Error al comenzar el proceso de pago. Por favor intenta de nuevo. {error}
          </div>
        </div>
      </>
    )
  }

  if (user) {
    console.log('user ==> ', user)
    // @ts-ignore
    window.location = user.url
  }

  return (
    <>
      {/* Main Body Pane */}
      <div className="w-full processing_screen">
        <Loading />
        <div className="justify-center text-center w-full text-black mt-6">Procesando</div>
      </div>
    </>
  )
}
