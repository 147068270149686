import { useLocation } from 'react-router-dom'
import { useApi } from '../hooks/use-api'
import wineCup from '../resources/wine-cup.png'
import { getShippingDate } from './utils'

interface CheckoutSession {
  id: string
  productId: string
  productName: string
  description: string
}

export function Success() {
  const query = new URLSearchParams(useLocation().search)
  const checkoutId = query.get('id')

  const { loading, error, data: session } = useApi(`/v1/checkout-session?id=${checkoutId}`, 'GET', {})

  if (loading) {
    return (
      <>
        <div className="flex flex-wrap w-full success_screen">
          <div className="justify-center text-center w-full text-black">
            <h2 className="text-2xl lg:text-4xl lg:leading-9 my-6">Bienvenido a Salvaje Club !</h2>
            <img src={wineCup} alt="Suscripcion" width="30px" className="m-auto mt-10 mb-0" />
            <p className="mt-6 m-auto text-xl">Procesando ...</p>
          </div>
        </div>
      </>
    )
  }

  if (error || session === null) {
    console.log('message ==> ', error)
    return (
      <>
        <div className="flex flex-wrap w-full success_screen">
          <div className="justify-center text-center w-full text-black">
            <h2 className="text-2xl lg:text-4xl lg:leading-9 my-6">Bienvenido a Salvaje Club !</h2>
            <img src={wineCup} alt="Suscripcion" width="30px" className="m-auto mt-10 mb-0" />
            <p className="mt-6 m-auto text-xl">
              Gracias por subscribirte a Salvaje Club !
              <br />
              Estamos procesando tu pago y recibiras un correo de confirmación en los proximos minutos.
            </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="success_container">
        <div className="flex flex-wrap w-full success_screen">
          <div className="justify-center text-center w-full text-black">
            <h2 className="text-2xl lg:text-4xl lg:leading-9 my-6">Bienvenido a Salvaje Club !</h2>
            <img src={wineCup} alt="Suscripcion" width="30px" className="m-auto mt-10 mb-0" />
          </div>

          <div className="grid grid-cols-3 pb-5 gap-3 px-5 mt-0 mb-7 m-auto">
            <div className="col-span-1 text-lg font-semibold"># de Confirmación</div>
            <div className="col-span-2 text-lg text-right">{(session as CheckoutSession).id}</div>
            <hr className="col-span-3 my-2 h-px bg-gray-700 border-0" />

            <div className="col-span-1 text-lg font-semibold">Paquete</div>
            <div className="col-span-2 text-lg text-right">{(session as CheckoutSession).productName}</div>
            <hr className="col-span-3 my-2 h-px bg-gray-700 border-0" />

            <div className="col-span-1 text-lg font-semibold">Descripción</div>
            <div className="col-span-2 text-lg text-right">{(session as CheckoutSession).description}</div>
            <hr className="col-span-3 my-2 h-px bg-gray-700 border-0" />

            <div className="col-span-1 text-lg font-semibold">Fecha primer envio</div>
            <div className="col-span-2 text-lg text-right ">{getShippingDate()}</div>
            <hr className="col-span-3 my-2 h-px bg-gray-700 border-0" />
          </div>

          <div className="text-center block mx-auto checkout-legend text-3xl w-full">
            Vinos salvajes, naturales y vivos
          </div>
        </div>
      </div>
    </>
  )
}
