import pkgCasualTitle from '../resources/pkg/casual-title.webp'
import pkgClassicTwoBottles from '../resources/pkg-classic-two-bottles.png'
import pkgClassicTitle from '../resources/pkg/classic-title.webp'
import pkgClassicThreeBottles from '../resources/pkg-classic-three-bottles.png'
import pkgPremiumTitle from '../resources/pkg/premium-title.webp'
import pkgPremiumBottles from '../resources/pkg-premium-bottles.png'
import wineCup from '../resources/wine-cup.png'
import seleccionMixtaTitle from '../resources/seleccion-mixta-title.png'
import seleccionMixtaBottles from '../resources/mixta-bottles.png'
import soloTintosTitle from '../resources/solo-tintos-title.png'
import soloTintosBottles from '../resources/solo-tintos-bottles.png'
import { useState } from 'react'
import { useRecoilState } from 'recoil'
import { currentUserState } from '../userState/state'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation, useNavigate } from 'react-router-dom'
import PkgStepOne from '../components/PkgStepOne'
import { Question } from '../components/Question'
import useMediaQuery from '../hooks/useMediaQuery'
import { CheckoutSummary } from '../components/CheckoutSummary'
import { getFirstPaymentDate, getNextPaymentDate } from './utils'

const CASUAL_PKG_NAME = 'casual'
const CLASSIC_PKG_NAME = 'classic'
const PREMIUM_PKG_NAME = 'premium'
const VARIETY_TINTOS = 'tintos'
const VARIETY_MIXTA = 'mixta'
const CASUAL_PKG_PRICE = '$1,600'
const CLASSIC_PKG_PRICE = '$2,220'
const PREMIUM_PKG_PRICE = '$6,000'

export function Home() {
  const [pkgName, setPkgName] = useState('')
  const [pkgVariety, setPkgVariety] = useState('')
  const [currentUser] = useRecoilState(currentUserState)
  const { loginWithRedirect } = useAuth0()
  const history = useNavigate()

  const query = new URLSearchParams(useLocation().search)
  const pkgNameQuery = query.get('pkg')
  const varietyQuery = query.get('variety')

  const wizardPickPackage = (name: string, variety: string) => {
    setPkgName(name)
    setPkgVariety(variety)
    const varietyParam = variety !== '' ? `&variety=${variety}` : ''

    switch (name) {
      case CASUAL_PKG_NAME:
        history(`${window.location.pathname}?pkg=${name}${varietyParam}`, { replace: true })
        break
      case CLASSIC_PKG_NAME:
        history(`${window.location.pathname}?pkg=${name}${varietyParam}`, { replace: true })
        break
      case PREMIUM_PKG_NAME:
        history(`${window.location.pathname}?pkg=${name}`, { replace: true })
        break
      default:
        history(window.location.pathname, { replace: true })
        break
    }
  }

  let isStepOne = pkgNameQuery === null && varietyQuery === null
  let isStepTwo = (pkgNameQuery === CASUAL_PKG_NAME || pkgNameQuery === CLASSIC_PKG_NAME) && varietyQuery === null
  let isStepThree =
    ((pkgNameQuery === CASUAL_PKG_NAME || pkgNameQuery === CLASSIC_PKG_NAME) &&
      (varietyQuery === VARIETY_TINTOS || varietyQuery === VARIETY_MIXTA)) ||
    pkgNameQuery === PREMIUM_PKG_NAME

  if (
    (pkgNameQuery !== null &&
      pkgNameQuery !== CASUAL_PKG_NAME &&
      pkgNameQuery !== CLASSIC_PKG_NAME &&
      pkgNameQuery !== PREMIUM_PKG_NAME) ||
    (varietyQuery !== null && varietyQuery !== VARIETY_MIXTA && varietyQuery !== VARIETY_TINTOS)
  ) {
    isStepOne = true
    isStepTwo = false
    isStepThree = false

    // @TODO: clear url params
  }

  let wizardTitle = 'Elige tu suscripción mensual'
  if (isStepTwo) {
    wizardTitle = 'Elige las variedades que prefieres'
  } else if (isStepThree) {
    wizardTitle = 'Confirma tu suscripción'
  }

  const checkout = () => {
    if (!currentUser) {
      // this means the user has not logged in/created a user
      loginWithRedirect({
        ui_locales: 'es',
        screen_hint: 'signUp',
        appState: { returnTo: `processing${window.location.search}&to_checkout=true` },
      })
    } else {
      window.location.href = `processing${window.location.search}&to_checkout=true`
    }
  }

  const getSubscriptionLabel = () => {
    let label = 'Suscripción'
    switch (pkgNameQuery) {
      case CASUAL_PKG_NAME:
        label = 'Casual'
        break
      case CLASSIC_PKG_NAME:
        label = 'Classic'
        break
      case PREMIUM_PKG_NAME:
        label = 'Premium'
        break
      default:
        break
    }

    return label
  }

  const getVarietyLabel = () => {
    if (pkgNameQuery === PREMIUM_PKG_NAME) {
      return ''
    }

    return 'Variedad'
  }

  const getPrice = () => {
    let price = undefined
    switch (pkgNameQuery) {
      case CASUAL_PKG_NAME:
        price = CASUAL_PKG_PRICE
        break
      case CLASSIC_PKG_NAME:
        price = CLASSIC_PKG_PRICE
        break
      case PREMIUM_PKG_NAME:
        price = PREMIUM_PKG_PRICE
        break
      default:
        break
    }

    return price
  }

  // You can use any @media property
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <>
      {/* Main Body Pane */}

      <section className="bg-salvaje-orange">
        <div className="hero-banner grid px-8 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12"></div>
      </section>

      <section className="wizard" id="wizard">
        <div className="w-full py-4 px-2 lg:p-6 breadcrumb-wrapper">
          <nav className="flex rounded-lg bg-gray-50 text-xl wizard-nav" aria-label="Breadcrumb">
            <ol className="inline-flex text-center space-x-1 md:space-x-3 w-full">
              <li
                className={`px-5 py-3 breadcrumb-item inline-flex text-center ${isStepOne ? 'selected' : 'completed'}`}
              >
                <div>
                  <img
                    className={`${isStepOne && isDesktop ? 'wine-glass' : 'hidden'}`}
                    src={wineCup}
                    alt="Suscripcion"
                    width="20px"
                  />
                </div>

                <span className="w-full font-medium" onClick={() => wizardPickPackage('', '')}>
                  {getSubscriptionLabel()}
                </span>
              </li>
              <li
                className={`px-5 py-3 breadcrumb-item inline-flex text-center ${
                  isStepTwo ? 'selected' : isStepThree ? 'completed' : ''
                }`}
              >
                <div>
                  <img
                    className={`${isStepTwo && isDesktop ? 'wine-glass' : 'hidden'}`}
                    src={wineCup}
                    alt="Variedad"
                    width="20px"
                  />
                </div>
                <span className="w-full font-medium" onClick={() => wizardPickPackage(pkgName, '')}>
                  {getVarietyLabel()}
                </span>
              </li>
              <li className={`px-5 py-3 breadcrumb-item inline-flex text-center ${isStepThree ? 'selected' : ''}`}>
                <div>
                  <img
                    className={`${isStepThree && isDesktop ? 'wine-glass' : 'hidden'}`}
                    src={wineCup}
                    alt="Checkout"
                    width="20px"
                  />
                </div>
                <span className="w-full font-medium">Checkout</span>
              </li>
            </ol>
          </nav>
        </div>

        <div className="justify-center text-center w-full text-black">
          <h2 className="text-2xl lg:text-4xl lg:leading-9 mb-4">{wizardTitle}</h2>
        </div>

        {isStepOne && (
          <>
            <div className="flex flex-col md:flex-row py-4 gap-6 px-8 cursor-pointer step-one-wrapper">
              <PkgStepOne
                pkgName={CLASSIC_PKG_NAME}
                pkgClassName={'pkg-classic'}
                wizardPickPackage={wizardPickPackage}
                price={'$ 2,200 mxn'}
                headerImg={pkgClassicTitle}
                bottleImg={pkgClassicThreeBottles}
                bottleImgWidth={'150px'}
                imgAltText={'Salvaje Classic 3 botellas'}
                description="3 botellas"
              />

              <PkgStepOne
                pkgName={CASUAL_PKG_NAME}
                pkgClassName={'pkg-casual'}
                wizardPickPackage={wizardPickPackage}
                price={'$ 1,600 mxn'}
                headerImg={pkgCasualTitle}
                bottleImg={pkgClassicTwoBottles}
                imgAltText={'Salvaje Casual 2 botellas'}
                description="2 botellas"
              />

              <PkgStepOne
                pkgName={PREMIUM_PKG_NAME}
                pkgClassName={'pkg-premium'}
                wizardPickPackage={wizardPickPackage}
                price={'$ 6,000 mxn'}
                headerImg={pkgPremiumTitle}
                bottleImg={pkgPremiumBottles}
                bottleImgWidth={'150px'}
                imgAltText={'Salvaje Premium 2 ó 3 botellas'}
                description="2 ó 3 botellas"
              />
            </div>
          </>
        )}

        {isStepTwo && (
          <>
            <div className="flex flex-col md:flex-row py-4 gap-6 px-8 cursor-pointer step-two-wrapper">
              <div className={`wizard-item-wrapper basis-1/2`} onClick={() => wizardPickPackage(pkgName, 'mixta')}>
                <div className="p-6 m-4">
                  <img src={seleccionMixtaTitle} alt="Seleccion Mixta" width="250px" className="mx-auto" />

                  <span className="text-lg font-bold text-center block my-8">
                    Selección de vinos tintos, blancos, rosés, naranjas & espumosos
                  </span>

                  <img src={seleccionMixtaBottles} alt="Seleccion Mixta" width="150px" className="mx-auto" />
                </div>
              </div>

              <div className={`wizard-item-wrapper basis-1/2`} onClick={() => wizardPickPackage(pkgName, 'tintos')}>
                <div className="p-6 m-4">
                  <img src={soloTintosTitle} alt="Solo Tintos" width="175px" className="mx-auto" />
                  <span className="text-lg font-bold text-center block my-8">
                    Selección únicamente de vinos tintos.
                  </span>

                  <img src={soloTintosBottles} alt="Solo Tintos" width="150px" className="mx-auto" />
                </div>
              </div>
            </div>
          </>
        )}

        {isStepThree && (
          <div className={'flex step-three-wrapper py-4 px-2'}>
            {!isMobile && <CheckoutSummary packageName={pkgNameQuery ?? ''} variety={varietyQuery ?? ''} />}

            <div className={`${isMobile ? '' : 'basis-3/5'} checkout-summary wizard-item-wrapper`}>
              <div className="summary-container mx-10 mt-10 mb-7">
                <div className="grid grid-cols-3 pt-5 pb-1 gap-3 px-5 mb-7">
                  <div className="summary-header text-3xl col-span-2">TOTAL</div>
                  <div className="summary-header text-3xl col-span-1 text-right">{getPrice()}</div>

                  <div className="col-span-2 text-base lg:text-xl">Pago mensual</div>
                  <div className="col-span-1 text-base leading-7 lg:text-lg text-right summary-details-data">
                    {getPrice()}
                  </div>
                  <hr className="col-span-3 my-2 h-px bg-gray-200 border-0 dark:bg-black-700" />

                  <div className="col-span-2 text-base lg:text-xl">Fecha de primer pago</div>
                  <div className="col-span-1 text-sm leading-7 lg:text-lg text-right summary-details-data">
                    {getFirstPaymentDate()}
                  </div>
                  <hr className="col-span-3 my-2 h-px bg-gray-200 border-0 dark:bg-black-700" />

                  <div className="col-span-2 text-base lg:text-xl">Fecha siguiente pago</div>
                  <div className="col-span-1 text-sm leading-7 lg:text-lg text-right summary-details-data">
                    {getNextPaymentDate()}
                  </div>
                  <hr className="col-span-3 my-2 h-px bg-gray-200 border-0 dark:bg-black-700" />
                </div>

                <button
                  type="button"
                  onClick={() => checkout()}
                  className="checkout-btn font-bold text-center block p-3 mb-7 mx-auto w-48 text-xl"
                >
                  CHECKOUT
                </button>
              </div>
              <div className="text-center block mx-auto checkout-legend text-3xl">
                Vinos salvajes, naturales y vivos
              </div>
            </div>
          </div>
        )}
      </section>

      <div className="flex flex-wrap text-left club-description">
        <div className="w-full text-black club-description-container">
          <h3 className="text-5xl mb-10 text-center">Salvaje Club por Salvaje Vinos</h3>
          <p className="mb-6 text-lg leading-8">
            Al suscribirte a Salvaje Club recibirás mensualmente una selección de vinos curada por Salvaje Vinos, así
            como privilegios y sorpresas reservadas solo para miembros del Club.
          </p>
          <p className="mb-6 text-lg leading-8">
            Para unirte, simplemente tienes que escoger el número de botellas que quieres recibir mensualmente y si
            prefieres una selección mixta o de únicamente vino tinto. Nosotras hacemos el resto.
          </p>
          <p className="mb-6 text-lg leading-8">
            La selección de vinos cambiará mensualmente e incluiremos sólo los vinos que consideramos los más
            interesantes y únicos. Salvaje Club es la mejor manera de recibir, de manera garantizada y exclusiva, vinos
            naturales de todas partes del mundo.
          </p>
          <p className="mb-6 text-lg leading-8">
            Al inscribirte al club pagarás tu primera caja y los próximos pagos se cobrarán automáticamente el mismo día
            cada mes.
          </p>
          <p className="mb-6 text-lg leading-8">
            Los paquetes se envían dentro de los primeros 10 días de cada mes. Si te inscribiste en los primeros 10 días
            del mes, recibiras tu paquete ese mes. Si te inscribes a partir del 11 del mes, recibiras tu primer paquete
            la primera semana del siguiente mes.
          </p>
          <p className="mb-6 text-lg leading-8">
            Si tienes alguna duda o comentario, escríbenos a hola@salvajevinos.com o mediante redes sociales.
          </p>
          <p className="text-base mb-4 italic">
            *La suscripción PREMIUM estará compuesta por 2 o 3 botellas de vino que sumadas sean, minimo, equivalentes
            al precio de la suscripción Premium.
          </p>
        </div>
      </div>

      <div className="flex flex-wrap text-left club-faq">
        <div className="w-full text-black club-faq-container">
          <h3 className="text-5xl leading-7 mb-8 text-center">FAQs</h3>
          <p className="mb-6 text-lg leading-8">
            Hemos recopilado algunas Preguntas Frecuentes del Salvaje Club, si no encuentras tu respuesta en estas
            preguntas, contáctanos vía email o redes sociales. ¡Nos encantaría platicarte más del club!
          </p>

          <Question question={'¿Cómo me inscribo y cómo funciona el Club Salvaje?'} defaultHide={false}>
            <span className="block mb-4">
              Para inscribirte solo selecciona uno de los planes que tenemos para tí. Ingresa tus datos y recibirás un
              correo de confirmación. La suscripción es mensual y los cargos se harán automáticamente a la tarjeta que
              ingreses en tu inscripción.
            </span>
          </Question>

          <Question question={'¿Cuándo recibo mi primera caja?'}>
            <ul className="list-inside mb-4">
              <li>
                Los paquetes se envían dentro de los primeros 10 días de cada mes. Si te inscribiste dentro de los
                primeros 10 días del mes enviaremos tu caja al siguiente día hábil.
              </li>
              <li>Si te inscribiste despues del 10, recibiras tu caja la primera semana del siguiente mes.</li>
              <li>Tus siguientes cajas se enviarán la primera semana de cada mes.</li>
            </ul>
          </Question>

          <Question question={'¿Qué recibo en mi caja?'}>
            <span className="block mb-4">
              Recibirás 2 o 3 botellas dependiendo del plan que hayas escogido, fichas técnicas electrónicas y recetas
              para acompañar tus vinos.
            </span>
          </Question>

          <Question question={'¿Qué beneficios obtengo con mi suscripción al club?'}>
            <ul className="list-inside mb-4">
              <li>Obtienes una selección de vino mensual curada por el equipo de Salvaje Vinos.</li>
              <li>Descuentos exclusivos en la tienda</li>
              <li>Prioridad en lanzamientos de nuevas etiquetas de edición limitada o difíciles de encontrar.</li>
              <li>Regalos y sorpresas exclusivos del club</li>
            </ul>
          </Question>

          <Question question={'¿El envío está incluído?'}>
            <span className="block mb-4">¡El envío está incluido en todos los planes!</span>
          </Question>

          <Question question={'¿Puedo pausar o cancelar mi suscripción?'}>
            <ul className="list-inside mb-4">
              <li>
                Por medio de la plataforma podrás pausar o cancelar tu suscripción en el momento que así lo desees.
              </li>
              <li>También te puedes poner en contacto con nuestro equipo al correo hola@salvajevinos.com</li>
            </ul>
          </Question>

          <Question question={'¿Puedo pedir una selección personalizada?'}>
            <span className="block mb-4">
              Sí, escríbenos a hola@salvajevinos.com y podemos curar una caja de vinos personalizada a tus gustos!
            </span>
          </Question>
        </div>
      </div>
    </>
  )
}
