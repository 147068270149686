class Environment {
  public get AUTH0_DOMAIN(): string {
    return process.env.REACT_APP_UI_AUTH0_DOMAIN ?? 'dev-yoyj2yuy.us.auth0.com'
  }

  public get AUTH0_AUDIENCE(): string {
    return process.env.REACT_APP_UI_AUTH0_AUDIENCE ?? 'https://salvajevinos.com/club'
  }

  public get AUTH0_CLIENT_ID(): string {
    return process.env.REACT_APP_UI_AUTH0_CLIENT_ID ?? ''
  }

  public get AUTH0_SCOPE(): string {
    return process.env.REACT_APP_UI_AUTH0_SCOPE ?? 'openid'
  }

  public get SERVER_URL(): string {
    return process.env.REACT_APP_SERVER_URL ?? 'http://localhost:4000'
  }
}

const env = new Environment()
export default env
