import casualTintos from '../resources/checkout/casual-tintos.png'
import casualMixta from '../resources/checkout/casual-mixta.png'
import classicTintos from '../resources/checkout/classic-tintos.png'
import classicMixta from '../resources/checkout/classic-mixta.png'
import premium from '../resources/checkout/premium.png'

interface CheckoutSummaryProps {
  packageName: string
  variety: string
}

const mixtaDescription = 'Selección de vinos tintos, rosados, naranjas, blancos y pet-nats.'
const tintosDescription = 'Selección de vinos tintos de diferente estilo.'

export function CheckoutSummary({ packageName, variety }: CheckoutSummaryProps) {
  switch (packageName) {
    case 'casual':
      if (variety === 'mixta') {
        return (
          <div className="basis-2/5 row-span-1 checkout-details wizard-item-wrapper p-8">
            <img src={casualMixta} alt="" className="my-11" />
            <span className="description text-2xl text-center block">{mixtaDescription}</span>
          </div>
        )
      }

      if (variety === 'tintos') {
        return (
          <div className="basis-2/5 checkout-details wizard-item-wrapper p-8">
            <img src={casualTintos} alt="" className="my-11" />
            <span className="description text-2xl text-center block">{tintosDescription}</span>
          </div>
        )
      }
      break
    case 'classic':
      if (variety === 'mixta') {
        return (
          <div className="basis-2/5 checkout-details wizard-item-wrapper p-8">
            <img src={classicMixta} alt="" className="my-11" />
            <span className="description text-2xl text-center block">{mixtaDescription}</span>
          </div>
        )
      }

      if (variety === 'tintos') {
        return (
          <div className="basis-2/5 checkout-details wizard-item-wrapper p-8">
            <img src={classicTintos} alt="" className="my-11" />
            <span className="description text-2xl text-center block">{tintosDescription}</span>
          </div>
        )
      }
      break
    case 'premium':
      return (
        <div className="basis-2/5 checkout-details wizard-item-wrapper p-8">
          <img src={premium} alt="" className="my-11" />
          <span className="description text-2xl text-center block">
            Selección de 2 ó 3 botellas vinos tintos, rosados, naranjas, blancos y pet-nats.
          </span>
        </div>
      )
    default:
      return null
  }

  return null
}
