import React from 'react'
import './Loading.css'
import { useAuth0 } from '@auth0/auth0-react'

function Logout() {
  const { isAuthenticated, logout } = useAuth0()

  if (isAuthenticated) {
    logout({ returnTo: window.location.origin })
  } else {
    window.location.replace(window.location.origin)
  }

  return (
    <div className="mt-0 h-14 w-full">
      <span className="loader m-auto block"></span>
    </div>
  )
}

export default Logout
