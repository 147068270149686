import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Auth0Provider, AppState } from '@auth0/auth0-react'
import env from '../env'

// @ts-ignore
const Auth0ProviderWithHistory = ({ children }) => {
  const history = useNavigate()
  const domain = env.AUTH0_DOMAIN
  const clientId = env.AUTH0_CLIENT_ID

  const onRedirectCallback = (appState: AppState | undefined) => {
    // If using a Hash Router, you need to use window.history.replaceState to
    // remove the `code` and `state` query parameters from the callback url.
    // window.history.replaceState({}, document.title, window.location.pathname);
    // history.replace((appState && appState.returnTo) || window.location.pathname)
    if (appState) {
      history(`${window.location.pathname}${appState?.returnTo}`, { replace: true })
    } else {
      history(window.location.pathname, { replace: true })
    }
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      scope={env.AUTH0_SCOPE}
      audience={env.AUTH0_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
